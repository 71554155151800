import { identificationTypes } from "./flow-base";
import * as events from "../events";
import {LoginFlowBase} from "./login-flow-base";

class SFCKELogin extends LoginFlowBase {

    ajaxIdentify() {
        if (this.metadata["page_attributes-no-identify-sfc"]) {
            this.identifyOnSuccess(this.fakeResponse())
            return
        }
        if (!this.internalCall){
            this.integrator.customHeIdentifyGetRequestWithAuth({
                return: window.location.origin + window.location.pathname,
            }, "https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn", this.heAuthToken, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this));
        }
    }

    identifyOnSuccess(response) { // overridable
        //for debugging purposes
        if (this.metadata["page_attributes-debug"]) {
            var elemDiv = document.createElement('div');
            elemDiv.innerHTML = '<p style="color: white">' + JSON.stringify(response) + '</p>'
            document.body.appendChild(elemDiv);
        }
        //Do a check here to see if the user is subscribed and redirect to product
        this.identifyCallFinished = true;
        if (response.ServiceResponse.ResponseBody.Response.Msisdn) {
            this.identified = true
            this.identity = response.ServiceResponse.ResponseBody.Response.Msisdn
            this.tracker.track(events.IDENTIFY_SUCCESS);
            // do the check here
            const msisdn = response.ServiceResponse.ResponseBody.Response.Msisdn
            const plan = this.plan
            this.msisdn = msisdn
            this.integrator.LPCheckSubscriptionRequest({msisdn,plan},this.subscriptionCheckOnSuccess.bind(this),this.subscriptionCheckOnFailure.bind(this))
            return
        }

        this.tracker.track(events.IDENTIFY_FAILURE);
        this.identifyBackup()
    }
    subscriptionCheckOnSuccess(response){
        const token = response.access_token

        if (token) {
            this.redirectToProduct(response.access_token);
        }else{
            this.showNoSubFoundErrorMessage()
        }
    }

    /**
     * Checks if identity has already happened, if so tries the retry plans
     */
    checkRetryPlans() {
        //if a retry then do check here
        let msisdn = this.msisdn

        if (this.msisdn && this.identity && this.identified) { //if they exist already we know this is a retry
            let retryPlans = []
            try {
                // array of 'page_attributes-retry_plans' is sent as comma seperated string, need to parse this to an array
                retryPlans = this.metadata['page_attributes-retry_plans'].split(",")
            } catch (e) {
                this.showNoSubFoundErrorMessage()
                return
            }
            for (let plan of retryPlans) {
                if (!this.checkedPlans.includes(plan)) {
                    this.checkedPlans.push(plan)
                    this.integrator.LPCheckSubscriptionRequest({msisdn,plan},this.subscriptionCheckOnSuccess.bind(this),this.subscriptionCheckOnFailure.bind(this))
                    return
                }
            }
        }
        this.showNoSubFoundErrorMessage()

    }

    subscriptionCheckOnFailure(response){
        this.logger.error(`[SFC Login Flow] failed to check for subscription: ${response} `)
        if (this.metadata['page_attributes-retry_plans']) {
            console.log('Retrying different plans')
            this.checkRetryPlans()
            return
        }
        this.showNoSubFoundErrorMessage() // change to show message to the user
    }

    showNoSubFoundErrorMessage() {
        this.uiControl.showErrorMessage("Sorry there was a problem identifying you, please contact support")
    }

    identifyOnError(response) { // overridable
        if (this.metadata["page_attributes-debug"]) {
            var elemDiv = document.createElement('div');
            elemDiv.innerHTML = '<p style="color: white">' + JSON.stringify(response) + '</p>'
            document.body.appendChild(elemDiv);
        }

        this.identifyCallFinished = true;
        this.tracker.track(events.IDENTIFY_FAILURE);
        this.identifyBackup()
    }

    fakeResponse() {
        return {
            "ServiceResponse" : {
                "ResponseBody" : {
                    "Response" : {
                        "Msisdn" : "123456789"
                    }
                }
            }
        }
    }

    overridableInit() {
        this.thirdPartyCallComplete = false;
        window.setTimeout(this.setThirdPartyCallComplete.bind(this), 20000);
        document.addEventListener('EvinaMsisdnSaved', () => {
            this.thirdPartyCallComplete = true;
            this.logger.info(`[SFC Flow] - ${this.metadata.event_tracking_id} - EvinaMsisdnSaved event`);
        }, false);
    }

    setThirdPartyCallComplete() {
        this.thirdPartyCallComplete = true;
    }

    isThirdPartyCallComplete() {
        return this.thirdPartyCallComplete;
    }
}

const SFCKEFlowLogin = new SFCKELogin('SFC KE Login', identificationTypes.AJAX, {
    subscribeClick: true,
    identifyTimeout: 20,
    exitHandlerEnabled: true,
})
window.FlowModule = SFCKEFlowLogin;
